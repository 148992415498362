import monitorTransformer from '@/services/transformers/monitorTransformer.js'
import nodeTransformer from '@/services/transformers/nodeTransformer.js'
import monitorCheckTransformer from '@/services/transformers/monitorCheckTransformer.js'
import snapshotTransformer from '@/services/transformers/snapshotTransformer.js'

import { cloneObject } from '@/services/utils.js'

const baseURL = 'monitors'

export default {
  /**
   * Get monitor list.
   *
   * @param params
   * @returns Promise
   */
  get (params = {}) {
    let paramsCopy = cloneObject(params)

    if (params.tags) {
      paramsCopy.tags = paramsCopy.tags.map(tag => tag.name)
    }

    return axios.get(`${baseURL}/`, {
      params: paramsCopy
    }).then(response => response.data.data.map(monitorTransformer))
  },

  search (search, take) {
    return axios.get(`${baseURL}/search`, {
      params: { search, take }
    }).then(response => response.data.map(monitorTransformer))
  },

  /**
   * Get paginated monitors.
   *
   * @param params
   * @returns Promise
   */
  getPaginatedMonitors (params) {
    let paramsCopy = cloneObject(params)

    if (params.tags) {
      paramsCopy.tags = paramsCopy.tags.map(tag => tag.name)
    }

    return axios.get(`${baseURL}/filtered/`, {
      params: paramsCopy
    }).then(response => ({
      data: response.data.data.map(monitorTransformer),
      meta: response.data.meta
    }))
  },

  /**
   * Get monitor nodes.
   *
   * @param id
   * @returns Promise
   */
  getNodes (id) {
    return axios.get(`${baseURL}/${id}/nodes`).then(response => nodeTransformer(response.data.data))
  },

  /**
   * Get monitor checks.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  async getChecks (id, params) {
    const response = await axios.get(`${baseURL}/${id}/checks`, { params })

    return {
      items: response.data.data.map(monitorCheckTransformer),
      meta: response.data.meta
    }
  },

  /**
   * Enable monitor node.
   *
   * @param id
   * @param node
   * @returns Promise
   */
  enableNode (id, node) {
    return axios.get(`${baseURL}/${id}/nodes/${node.id}/enable`)
  },

  /**
   * Disable monitor node.
   *
   * @param id
   * @param node
   * @returns Promise
   */
  disableNode (id, node) {
    return axios.get(`${baseURL}/${id}/nodes/${node.id}/disable`)
  },

  /**
   * Get specific monitor.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  find (id, params = {}) {
    return axios.get(`${baseURL}/${id}`, {
      params
    }).then(response => monitorTransformer(response.data.data))
  },

  /**
   * Store new monitor.
   *
   * @param params
   * @returns Promise
   */
  store (params) {
    return axios.post(baseURL, params).then(response => monitorTransformer(response.data.data))
  },

  /**
   * Quickly add new monitor with GET request method.
   *
   * @param urls
   * @returns Promise
   */
  quickStore (urls) {
    return axios.post(baseURL, {
      urls,
      request: {
        method: 'get'
      }
    }).then(response => response.data)
  },

  /**
   * Run monitor.
   *
   * @returns Promise
   */
  run (id) {
    return axios.post(`${baseURL}/${id}/run`).then(response => response.data)
  },

  /**
   * Pause monitor.
   *
   * @returns Promise
   */
  pause (id) {
    return axios.post(`${baseURL}/${id}/pause`).then(response => response.data)
  },

  multipleRun (idsArray) {
    return axios.post(`${baseURL}/multiple-run`, { monitors: JSON.stringify(idsArray) })
      .then(response => response.data)
  },
  multiplePause (idsArray) {
    return axios.post(`${baseURL}/multiple-pause`, { monitors: JSON.stringify(idsArray) })
      .then(response => response.data)
  },

  /**
   * Update monitor.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  update (id, params) {
    return axios.patch(`${baseURL}/${id}`, params).then(response => response.data.data)
  },

  /**
   * Telegram connectivity check.
   *
   * @param id
   * @returns Promise
   */
  checkTelegram (id) {
    return axios.get(`${baseURL}/${id}/checkTelegram`).then(response => response.data)
  },

  leaveTelegramChat (id, chat_id) {
    return axios.delete(`${baseURL}/${id}/leaveTelegramChat/${chat_id}`).then(response => response.data)
  },

  /**
   * Run check.
   *
   * @param id
   * @returns Promise
   */
  runCheck (id) {
    return axios.post(`${baseURL}/${id}/run-check`).then(response => response.data)
  },

  /**
   * Get notification channels.
   *
   * @param id
   * @returns Promise
   */
  getNotificationChannels (id) {
    return axios.get(`${baseURL}/${id}/notification-channels`).then(response => response.data.data)
  },

  /**
   * Update notification channels.
   *
   * @param id
   * @param channels
   * @returns Promise
   */
  updateNotificationChannels (id, channels) {
    return axios.post(`${baseURL}/${id}/notification-channels`, { channels }).then(response => response.data.data)
  },

  /**
   * Get maintenance windows.
   *
   * @param id
   * @returns Promise
   */
  getMaintenanceWindows (id) {
    return axios.get(`${baseURL}/${id}/maintenance-windows`)
      .then(response => response.data.data)
  },

  /**
   * Store maintenance window.
   *
   * @param id
   * @param attributes
   * @returns Promise
   */
  storeMaintenanceWindow (id, attributes) {
    return axios.post(`${baseURL}/${id}/maintenance-windows`, attributes)
      .then(response => response.data.data)
  },

  /**
   * Delete monitor.
   *
   * @param id
   * @returns Promise
   */
  delete (id) {
    return axios.delete(`${baseURL}/${id}`).then(response => response.data)
  },

  multipleDelete (idsArray) {
    return axios.post(`${baseURL}/multiple-delete`, { monitors: JSON.stringify(idsArray) })
      .then(response => response.data)
  },

  resetStats (id) {
    return axios.post(`${baseURL}/${id}/reset-statistics`)
      .then(response => response.data)
  },

  multipleResetStats (idsArray) {
    return axios.post(`${baseURL}/multiple-reset-statistics`, { monitors: JSON.stringify(idsArray) })
      .then(response => response.data)
  },

  /**
   * Get Monitor check stats.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  getCheckStats (id, params = {}) {
    return axios.get(`${baseURL}/${id}/check-stats`, { params }).then(response => response.data)
  },

  /**
   * Check if the url has been already added.
   *
   * @param url
   * @returns Promise
   */
  checkIfAlreadyAdded (url) {
    return axios.get(`${baseURL}/check-url`, { params: { url } }).then(response => response.data)
  },

  /**
   * Get snapshots.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  async getSnapshots (id, params) {
    const response = await axios.get(`${baseURL}/${id}/snapshots`, {
      params
    })

    return response.data.map(snapshotTransformer)
  },

  /**
   * Get statistics.
   *
   * @param id
   * @returns Promise
   */
  async getStatistics (id) {
    const response = await axios.get(`${baseURL}/${id}/statistics`)

    return response
  },

  /**
   * Get statistics by range.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  async getStatisticsByRange (id, params) {
    const response = await axios.get(`${baseURL}/${id}/statistics-by-range`, {
      params
    })

    return response
  },

  /**
   * Get node categories.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  async getNodeCategories (id, params) {
    const response = await axios.get(`${baseURL}/${id}/nodecategories`, {
      params
    })

    return response.data
  },

  /**
   * Save tags.
   *
   * @param id
   * @param tags
   * @returns Promise
   */
  async saveTags (id, tags) {
    const response = await axios.put(`${baseURL}/${id}/tags`, { tags })

    return response.data
  },

  async saveBulkTags (ids, tags) {
    const response = await axios.post(`${baseURL}/tags/bulk`, { ids, tags })

    return response.data
  },

  arrangeMonitors (data) {
    return axios.post(`${baseURL}/arrange-monitors`, data)
  },

  async getMonitorAverageResponseTimeByRange (id, params) {
    const response = await axios.get(`${baseURL}/${id}/average-response-time`, {
      params
    })
    return response.data
  },

  async getMonitorAverageUptimeByRange (id, params) {
    const response = await axios.get(`${baseURL}/${id}/average-uptime`, {
      params
    })
    return response.data
  },

  // Store response time filters for monitors
  storeResponseTimeFilters (params) {
    return axios.post(`${baseURL}/responseTimeFilters`, params)
  },

  async saveMonitorGroups (statusPageId, payload) {
    const response = await axios.post(`/status-page-monitors-group/${statusPageId}`, payload)
    return response.data
  },

  async getMonitorGroups (statusPageId) {
    const response = await axios.get(`/status-page-monitors-group/${statusPageId}`)
    return response.data
  },

  async getMonitorEachDayUptime (id, params) {
    const response = await axios.get(`/public/monitor/${id}/each-day-uptime`, {
      params,
      transformRequest: [(data, headers) => {
        delete headers.Authorization
        return data
      }]
    })
    return response.data
  },

  storeMonitorSaveReportOptions (params) {
    return axios.post(`${baseURL}/store-monitor-save-report-options`, params)
  },

  duplicateMonitor (id) {
    return axios.post(`${baseURL}/${id}/duplicate`)
  }
}
